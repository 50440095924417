$desktop_small: 1024px;
$desktop_medium: 1299px;
$tablet: 900px;
$mobile: 600px;

.CP_public_home {
    background-color: #ffffff;

    .hero_section {

        height: 550px;
        overflow: hidden;
        background: transparent linear-gradient(180deg, #01689E 0%, #207BA7 18%, #1CADD7 100%) 0% 0% no-repeat padding-box;

        @media screen and (max-width: $tablet) {
            height: unset;
        }

        .hero_section_wrapper {
            display: flex;
            padding: 0 0 0 4rem;
            height: 100%;

            @media screen and (min-width: 1700px) {
                padding: 0 0 0 11rem;
            }
            @media screen and (min-width: 1900px) {
                padding: 0 0 0 18rem;
            }

            @media only screen and (max-width: $tablet) {
                display: block;
                padding: 0 1rem;
            }

            .hero_left {
                width: 45%;
                align-self: center;

                @media only screen and (max-width: $tablet) {
                    width: 100%;
                }

                h1 {
                    color: #ffffff;
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 1.4;
                    margin: 1rem 0;
                }

                p {
                    font-size: 19px;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 1.5;
                    margin: 2.5rem 0;
                }

                .btn_white {
                    color: #0E699C;
                    background-color: #ffffff;
                    border: 1px solid #0E699C;
                    font-size: .875rem;
                    font-weight: 500;
                    width: 210px;
                    height: 40px;
                    transition: all cubic-bezier(0.075, 0.82, 0.165, 1);

                    &:hover {
                        background-color: #0E699C;
                        color: #ffffff;
                        border: 1px solid #ffffff;
                    }
                }
            }

            .hero_right {
                width: 55%;
                position: relative;
                align-self: flex-end;

                .hero_right_image {
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                @keyframes vertical-shaking {
                    0% {
                        transform: translateY(0)
                    }

                    25% {
                        transform: translateY(8px)
                    }

                    50% {
                        transform: translateY(-8px)
                    }

                    75% {
                        transform: translateY(8px)
                    }

                    100% {
                        transform: translateY(0)
                    }
                }

                @media only screen and (max-width: $tablet) {
                    width: 100%;
                }
            }
        }
    }

    .body_wrapper {
        padding: 2rem 0;

        .qntm-wrapper {
            max-width: 1500px;
            margin: auto;
        }


        .section-wrapper {
            margin-bottom: 2.5rem;
        }

        .icon-tab {
            text-align: center;

            .nav-link {
                background-color: #E7F7FB;
                border: 1px solid #E7F7FB;
                border-radius: 4px;
                cursor: pointer;

                &.active {
                    background-color: #ffffff;
                    border: 1px solid #0E699C;
                }

                .tab-button {
                    width: auto;
                    height: 10rem;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 1rem;

                    @media screen and (min-width: 1399px) {
                        height: 200px;
                    }

                    @media screen and (max-width: 1200px) {
                        height: 140px;
                    }

                    h3 {
                        font-size: 20px;
                        font-weight: 600;
                        color: #0E699C;
                    }
                }
            }

            .fade:not(.show) {
                display: none;
            }

            &-content {
                h3 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #0E699C;
                }

                p {
                    color: #333333;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 1.4;
                }
            }

            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        font-size: 16px;
                        font-weight: 600;
                        color: #0E699C;
                        display: inline-flex;
                        align-items: center;
                        gap: .5rem;
                        box-shadow: none;
                        img{
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
                .accordion-body{
                    p{
                        font-size: 14px;
                        color: #333333;
                        font-weight: 400;
                        text-align: left;
                        line-height: 1.5;
                    }
                }
            }
        }

        .tools-wrapper {
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            @media screen and (max-width: 992px) {
                flex-direction: column;
                gap: 3rem;
            }

            .layer_content {
                text-align: center;

                @media screen and (min-width:992px) and (max-width: 1200px) {
                    text-align: right;
                    max-width: 400px;
                }

                h5 {
                    color: #01689E;
                    font-size: 28px;
                    font-weight: 300;
                }
            }

            .divider {
                height: 86px;
                border-left: 1px solid #D1D1D1;
                margin: 0 3rem;

                @media screen and (max-width: 992px) {
                    display: none;
                }
            }

            .img-panel {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 3rem;
                flex-wrap: wrap;

                @media screen and (max-width: 575px) {
                    gap: auto;

                    img {
                        margin: 0 .1rem;
                    }
                }
            }
        }

        .whyChoose {
            text-align: center;

            .icon_panel {
                width: 127px;
                height: 127px;
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                border: 1px solid #D1D1D1;
                margin: auto;
            }

            h3 {
                color: #0E699C;
                font-size: 18px;
                font-weight: 600;
                line-height: 2;
            }

            p {
                color: #333333;
                font-size: 16px;
                font-weight: 400;
                line-height: 1.3;
            }
        }

        .layer_content {
            h1 {
                color: #199AD7;
                font-size: 28px;
                font-weight: 400;
                margin-bottom: 2rem;
            }

            h4 {
                color: #0E699C;
                font-size: 18px;
                font-weight: 500;
            }

            p {
                font-size: 16px;
                color: #333333;
                font-weight: 400;
                line-height: 1.8;
            }
        }

        ul.list {
            list-style-type: none;
            padding-left: 3rem;

            li {
                color: #333333;
                font-size: 16px;
                font-weight: 400;
                line-height: 1.5;
                margin: .5rem 0;
                position: relative;

                &::before {
                    background-image: url(../../assets/images/quantum/bullet.svg);
                    background-size: 100%;
                    content: '';
                    left: -25px;
                    position: absolute;
                    height: 14px;
                    width: 14px;
                    top: 5px;
                }
            }
        }

        .security_item {
            margin: 0 1rem;

            h3 {
                color: #333333;
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: .875rem;
            }

            p {
                font-size: .875rem;
                color: #333333;
                font-weight: 400;
                line-height: 1.5;
            }
        }

        .functionality_layer {
            min-height: 367px;
            padding: 2rem 0;
            background: transparent linear-gradient(107deg, #1CADD7 0%, #0E699C 100%) 0% 0% no-repeat padding-box;

            h1 {
                font-size: 28px;
                color: #ffffff;
                font-weight: 500;
                margin-bottom: 1.5rem;
            }

            .functionality_row {
                display: flex;
                justify-content: center;
                margin: 0 1rem;
                gap: 4rem;
                flex-wrap: wrap;

                @media only screen and (max-width: $tablet) {
                    justify-content: initial;
                    gap: 2rem;

                }

                .fun_card {
                    color: #FFFFFF;
                    border-radius: 4px;
                    padding: 1rem;
                    text-align: center;
                    min-height: 275px;
                    width: 275px;

                    @media only screen and (max-width: $tablet) {
                        width: 350px;
                    }

                    @media only screen and (max-width: $mobile) {
                        width: auto;
                    }

                    .card_icon {
                        width: 90px;
                        height: 90px;
                        background-color: #EBEBEB;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    h3 {
                        color: #199AD7;
                        font-size: 1rem;
                        font-weight: 600;
                        margin: 1rem 0;
                    }

                    p {
                        color: #333333;
                        font-size: .875rem;
                        font-weight: 400;
                        line-height: 1.5;
                        height: 84px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                    }
                }
            }
        }

        .customers_sec {
            padding: 2rem 0;

            .client_listing {
                display: flex;
                justify-content: center;

                .client_logo {
                    width: 250px;
                    height: 80px;

                    img {
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}