$desktop_small: 1024px;
$desktop_medium: 1299px;
$tablet: 900px;
$mobile: 600px;


#publicheader {
    top: -100px;
    z-index: 9;
    transition: top 0.5s ease-in-out;
    min-height: 70px;    
}

#publicheader.publicsticky {
    position: sticky;
    top: 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 0.2);   
    min-height: 60px;     
}


.CP_header {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 2rem;

    @media only screen and (max-width: $mobile) {
        padding: 1rem 0;
        flex-direction: column;
        gap: 1rem;
    }

    .logo {
        width: 226px;
        height: 33px;

        img {
            object-fit: cover;
            width: 100%;
        }
    }

    .buttonBlock {
        display: flex;
        gap: 1.5rem;
    }
}

.qtfy_footer {
    background-color: #4D4D4D;
    padding: 1.5rem 0rem;

    .copy_rights {
        font-size: .875rem;
        font-weight: 500;
        color: #ffffff;
    }

    .footer_page_link {
        color: #ffffff;
        margin: 0 1rem;

        a {
            font-size: .875rem;
            font-weight: 400;
            color: #ffffff;
            text-decoration: none;


            &:hover {
                color: #ffffff;
                text-decoration: none;
            }
        }

    }

    .soc_icon {
        width: 34px;
        height: 34px;
    }

    .poweredBY {
        color: #D1D1D1;
        font-weight: 500;
        font-size: .75rem;
        margin-right: 3px;
        line-height: 0;
        white-space: nowrap;
    }

    .footer_logo {
        width: 6.438rem;
    }
}

.bg-dullWhite {
    background-color: #f7f7f7;
}